@keyframes popUpIcon{
	0%{
		transform: scale(0.9);
    }
    50%{
		transform: scale(1.1);
	}
	100%{
        transform: scale(1);
	}
}
.MuiAvatar-circle{
    animation: popUpIcon 0.4s linear;
}